<template>
	<!-- 广告计划 -->
	<div class="program">
		<div class="gongdan">
			<div class="dan">
				<div class="text"><span>*</span>广告主ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="advertiser_account_id" @change="onAdvertData" placeholder="请填写广告主账户ID（限1条）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>代理商选择:</div>
				<div class="inputbox">
					<el-select v-model="adv_main_id" @change="onSelect" @visible-change='onVisibleSelect' placeholder="填写广告主ID可直接匹配代理商">
						<el-option v-for="(item,key) in agentoptions" :key="key" :label="item.advertiser_name" :value="item.adv_main_id"></el-option>
					</el-select>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>广告主公司名称:</div>
				<div class="inputbox"><el-input v-model="company_name" placeholder="请填写广告主营业执照名称"></el-input></div>
			</div>
			
			<div class="dan">
				<div class="text"><span>*</span>广告计划ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="advertising_program_id" placeholder="请填写广告计划ID（限1条）"></el-input></div>
			</div>
			<!-- <div class="dan">
				<div class="text">复审工单号:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="review_number" placeholder="如果有，请填写；如果无，请忽略"></el-input></div>
			</div> -->
			<!-- <div class="dan">
				<div class="text">落地页链接:</div>
				<div class="inputbox"><el-input v-model="landing_page_link" placeholder="请填写落地页链接，如：www.baidu.com"></el-input></div>
			</div> -->
			<!-- <div class="dan">
				<div class="text"><span>*</span>一审拒绝理由:</div>
				<div class="inputbox"><el-input type="textarea" :rows="3" v-model="first_refuse_to_reason" placeholder="请将一审拒绝理由复制粘贴至此处"></el-input></div>
			</div> -->
			<!-- <div class="dan">
				<div class="text"><span>*</span>复审申述理由:</div>
				<div class="inputbox"><el-input type="textarea" :rows="3" v-model="cotinue_refuse_to_reason" placeholder="请友善沟通，攻击性言论将被标记，严肃处理"></el-input></div>
			</div> -->
			<div class="dan">
				<div class="text">附件:</div>
				<div class="inputbox">
					<el-upload class="upload-demo" drag :disabled="agent_company_id == ''" :action="(agent_company_id > 0 ? $api.uploadRepairOrderFile : $api.file_upload) + '?token=' + token" :limit="10" 
						ref="upload"
						accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.csv,.xls,.xlsx,.ppt,.zip,.rar,.mp3,.m4a,.mp4"
						show-file-list
						:file-list="fileListUpload"
						:data="uploadData"
						:on-exceed="onExceedtoupload"
						:on-remove="onRemoveupload"
						:on-success="onSuccessupload"
						:before-upload="beforeAvatarUpload"
						:on-change="onChangeUpload">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text" v-if="agent_company_id == ''"><em>请选择代理商后才可上传</em></div>
						<div class="el-upload__text" v-else>将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">文件不大于50MB，最多可传10个,扩展名为：.jpg，.jpeg，.png，.doc，.docx，.pdf，.csv，.xls，.xlsx，.ppt，.zip，.rar，.mp3，.m4a，.mp4</div>
					</el-upload>
				</div>
			</div>
		</div>
		<div class="tijiaoanniu">
			<el-button type="primary" @click="onSubmit">确认提交</el-button>
		</div>
		<el-dialog title="提示" :visible.sync="SuceeDialogVisible" width="400px">
			<span>提交成功</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="SuceeDialogVisible = false">继续创建工单</el-button>
				<el-button type="primary" @click="toWorkList">返回列表页</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data () {
		return {
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			agentoptions:[],
			adv_main_id:"",//广告代理商
			VisibleSelect:0,//广告代理商打开弹窗次数
			company_name:'',//广告主公司名称
			advertiser_account_id:'',//广告主账户ID
			advertising_program_id:'',//广告计划ID
			review_number:'',//复审工单号
			landing_page_link:'',//落地页链接
			first_refuse_to_reason:'您好，视频封面及内容有问题，请修改，谢谢。',//一审拒绝理由
			cotinue_refuse_to_reason:'您好，请平台明确指出审核未通过原因，谢谢',//复审申诉理由
			temp_class:'ReviewTemp',//固定值
			uploadData:{
				file_id:101,
			},//上传文件时传送的参数
			attachment_id:[],//上传文件列表只取ID
			attachment_name:[],//文件名
			fileListUpload:[],//上传的文件列表
			delenteAnniu:false,//按钮禁用&&启用
			SuceeDialogVisible:false,
			agent_company_id:"",//
		}
	},

	mounted(){
		this.ongetAdvertiserList()
	},

	methods: {
		//广告主id失去焦点时
		onAdvertData(){
			var prame = {
				agent_id:this.advertiser_account_id,
			}
			this.$service.get(this.$api.getPortForAdvertiser,prame, (res)=> {
				if(res.code == 200){
					if(res.data.port_id){
						this.adv_main_id = res.data.port_id
						this.agent_company_id = this.onAgentCom(this.adv_main_id)
						this.$message({message: '已为您自动匹配代理商',type: 'success'})
					}
				}
			})
		},
		//广告代理商选择时
		onSelect(){
			this.agent_company_id = this.onAgentCom(this.adv_main_id)
			this.uploadData.adv_main_id = this.adv_main_id
			console.log(this.agent_company_id > 0)
			this.$refs.upload.clearFiles()
			this.ongetRepairOrderLog()
		},
		//获取字段
		onAgentCom(adv_main_id){
			var agent_company_id ;
			this.agentoptions.forEach((v,k) => {
				if(v.adv_main_id == adv_main_id){
					agent_company_id = v.agent_company_id
				}
			});
			return agent_company_id
		},
		//广告代理商下拉时
		onVisibleSelect(e){
			if(e && !this.VisibleSelect){
				this.VisibleSelect++
				this.$alert('填写广告主ID可直接匹配代理商', '提示', {
					confirmButtonText: '确定',
					callback: action => {}
				})
			}
		},
		//获取代理商列表
		ongetAdvertiserList(){
			this.$service.get(this.$api.getAdvertiserList,{}, (res)=> {
				if(res.code == 200){
					// res.data.forEach((v,k) => {
					// 	v.status = 0
					// });
					this.agentoptions = res.data
				}
			})
		},
		//获取最近一次提交
		ongetRepairOrderLog(){
			var prame ={
				ad_main_id:this.adv_main_id,
				temp_class:'ReviewTemp'
			}
			this.$service.get(this.$api.getRepairOrderLog,prame, (res)=> {
				if(res.code == 200){
					if(res.data != null){
						console.log(123123)
						if(this.company_name == '' && this.advertiser_account_id == '' && this.advertising_program_id == ''){
							this.company_name = res.data.content_json.company_name
							this.advertiser_account_id = res.data.content_json.advertiser_account_id
							this.advertising_program_id = res.data.content_json.advertising_program_id
						}
					}
				}
			})
		},
		// 确认提交
		onSubmit(){
			if(this.adv_main_id == ''){this.$message({message: '请选择广告代理商', type: 'warning'}); return}
			if(this.company_name == ''){this.$message({message: '请填写广告主营业执照名称', type: 'warning'}); return}
			if(this.advertiser_account_id == ''){this.$message({message: '请填写广告主账户ID', type: 'warning'}); return}
			if(this.advertising_program_id == ''){this.$message({message: '请填写广告计划ID', type: 'warning'}); return}
			if(this.first_refuse_to_reason == ''){this.$message({message: '请填写一审拒绝理由', type: 'warning'}); return}
			if(this.cotinue_refuse_to_reason == ''){this.$message({message: '请填写复审申诉理由', type: 'warning'}); return}
			this.attachment_id = []
			this.fileListUpload.forEach((v,k) => {
				if(v.response.data.fileId){
					this.attachment_id.push(v.response.data.fileId)
					this.attachment_name.push(v.response.data.fileName)
				}else{
					this.attachment_id.push(v.response.data.id)
					this.attachment_name.push(v.response.data.fileName)
				}
			});
			var prame = {
				adv_main_id:this.adv_main_id,//广告代理商
				company_name:this.company_name,//广告主公司名称
				advertiser_account_id:this.advertiser_account_id,//广告主账户ID
				advertising_program_id:this.advertising_program_id,//广告计划ID
				review_number:this.review_number,//复审工单号
				landing_page_link:this.landing_page_link,//落地页链接
				first_refuse_to_reason:this.first_refuse_to_reason,//一审拒绝理由
				cotinue_refuse_to_reason:this.cotinue_refuse_to_reason,//复审申诉理由
				temp_class:this.temp_class,//固定值
				attachment_id:this.attachment_id,//上传文件列表只取ID
				file_name:this.attachment_name.toString(),//提交文件名字段
			}
			const fullscreenLoading = this.$loading({
				lock: true,
				text: '提交中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			// console.log(this.agent_company_id > 0 ? '巨量' : '本地')
			this.$service.post((this.agent_company_id > 0 ? this.$api.createRepairOrderJs : this.$api.createCustomRepairOrder),prame, (res)=> {
				if(res.code == 200) {
					this.adv_main_id = "",//广告代理商
					this.company_name = '',//广告主公司名称
					this.advertiser_account_id = '',//广告主账户ID
					this.advertising_program_id = '',//广告计划ID
					this.review_number = '',//复审工单号
					this.landing_page_link = '',//落地页链接
					this.first_refuse_to_reason = '您好，视频封面及内容有问题，请修改，谢谢。',//一审拒绝理由
					this.cotinue_refuse_to_reason = '您好，请平台明确指出审核未通过原因，谢谢',//复审申诉理由
					this.temp_class = 'ReviewTemp',//固定值
					this.attachment_id = [],//上传文件列表只取ID
					this.fileListUpload = []//上传的文件列表
					this.attachment_name = []//上传的文件名
					this.SuceeDialogVisible = true
					// this.agent_company_id = ""//
					// this.$message({message: '提交成功',type: 'success'})
				}else{
					this.$message({message: res.message, type: 'warning'});
				}
			})
			fullscreenLoading.close()
		},
		//提交成功返回
		toWorkList(){
			this.$router.push('/workList')
		},
		//文件上传成功，失败 所有都会调用
		onChangeUpload(){
			this.delenteAnniu = false
		},
		//文件上传超出
		onExceedtoupload(){
			this.$message({message: '上传文件已超出10个', type: 'warning'});
		},
		//文件上传成功
		onSuccessupload(res, file,fileList){
			if(res.code == 200){
				this.fileListUpload = fileList
			}else{
				this.$refs.upload.uploadFiles.pop()//上传失败后移出刚上传的文件
				this.$message.error(res.msg);
				// console.log(this.$refs.upload.uploadFiles)
			}
			// console.log(res,'上传成功')
			// console.log(fileList,'上传成功')
			// console.log(this.fileListUpload,'文件列表')
		},
		//上传文件移出
		onRemoveupload(file, fileList){
			this.fileListUpload = fileList
			// console.log(file, fileList,'文件移出')
		},
		//上传文件前设置参数
		beforeAvatarUpload(file) {
			this.delenteAnniu = true
			if(this.adv_main_id == ''){
				this.$message.error('请选择广告代理商');
				return false
			}else{
				this.uploadData.adv_main_id = this.adv_main_id
			}
			const isLt50M = file.size / 1024 / 1024 < 10
			if(!isLt50M) {
				this.$message({
					message: '上传文件大小不能超过 50MB!',
					type: 'warning'
				});
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.program{
	width: 100%;
}
</style>
